<template>
  <b-nav-item :to="route" :exact="exact" exact-active-class="active" active-class="active">
    <feather-icon :icon="icon" size="16" class="mr-0 mr-sm-50" />
    <span class="d-none d-md-inline">{{ title }}</span>
  </b-nav-item>
</template>

<script>
import { BCard, BNav, BNavItem } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BNav,
    BNavItem,
  },
  props: {
    route: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    exact: {
      type: Boolean,
      default: true
    },
  },
};
</script>
